import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { HolidaySchema } from '../../shared/schemas';
import { HolidayModel } from './holiday.model';

@Injectable()
export class HolidayApi {
  private endpoint = 'holidays/';

  public constructor(private gateway: ApiGateway) {}

  public load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('Holiday', res)),
      map((data) => normalize(data, arrayOf(HolidaySchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public add(holidayData): Observable<any> {
    return this.gateway.post(this.endpoint, holidayData).pipe(
      map((res) => reformatEntityResponse('Holiday', res)),
      map((data) => normalize(data, HolidaySchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(id, data): Observable<any> {
    return this.gateway.put(this.endpoint + id, data).pipe(
      map((res) => reformatEntityResponse('Holiday', res)),
      map((data) => normalize(data, HolidaySchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(holidayId): Observable<any> {
    return this.gateway.get(this.endpoint + holidayId).pipe(
      map((res) => reformatEntityResponse('Holiday', res)),
      map((data) => normalize(data, HolidaySchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(holidayId): Observable<any> {
    return this.gateway.delete(this.endpoint + holidayId);
  }

  public calendars() {
    return this.gateway.get(this.endpoint + 'calendars');
  }

  public import(importData) {
    return this.gateway.post(this.endpoint + 'import', importData).pipe(
      map((res) => reformatListResponse('Holiday', res)),
      map((data) => normalize(data, arrayOf(HolidaySchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public fetchAvailableHolidaysForImport(importData): Observable<any> {
    const { calendar, region, year } = importData;

    return this.gateway.get(`${this.endpoint}calendars/${calendar}`, { params: { region, year } });
  }

  public batchImportHolidays(holidays: HolidayModel[]): Observable<any> {
    return this.gateway.post(`${this.endpoint}batch/`, holidays).pipe(
      map((res) => reformatListResponse('Holiday', res)),
      map((data) => normalize(data, arrayOf(HolidaySchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public clearHolidaysForSelectedYear(
    year: string,
    ignoreMetaMessage = false,
    holidayGroupId?: string,
  ): Observable<HolidayModel[]> {
    return this.gateway.delete(`${this.endpoint}year/${year}`, { headers: { ignoreMetaMessage } }, undefined, {
      holiday_group_id: holidayGroupId,
    });
  }
}
