import { UnsafeAction as Action } from '../../interfaces';
import { HolidayModel } from './holiday.model';

const ACTION_PREFIX = '[Holiday]';

export const holidayActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,

  IMPORT: `${ACTION_PREFIX} Import`,
  IMPORT_SUCCESS: `${ACTION_PREFIX} Import Success`,
  IMPORT_FAILED: `${ACTION_PREFIX} Import Failed`,

  FETCH_FOR_IMPORT: `${ACTION_PREFIX} Fetch for Import`,
  FETCH_FOR_IMPORT_SUCCESS: `${ACTION_PREFIX} Fetch for Import Success`,
  FETCH_FOR_IMPORT_FAILED: `${ACTION_PREFIX} Fetch for Import Failed`,

  IMPORT_SELECTED: `${ACTION_PREFIX} Import selected`,
  IMPORT_SELECTED_SUCCESS: `${ACTION_PREFIX} Import selected Successs`,
  IMPORT_SELECTED_FAILED: `${ACTION_PREFIX} Import selected Failed`,

  CLEAR: `${ACTION_PREFIX} Clear`,
  CLEAR_SUCCESS: `${ACTION_PREFIX} Clear Success`,
  CLEAR_FAILED: `${ACTION_PREFIX} Clear Failed`,
};

export class HolidayAction {
  public static load(): Action {
    return {
      type: holidayActionType.LOAD,
    };
  }

  public static loadSuccess(holiday): Action {
    return {
      type: holidayActionType.LOAD_SUCCESS,
      payload: holiday,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: holidayActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(holidayData): Action {
    return {
      type: holidayActionType.ADD,
      payload: holidayData,
    };
  }

  public static addSuccess(holidayResponse): Action {
    return {
      type: holidayActionType.ADD_SUCCESS,
      payload: holidayResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: holidayActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(holidayData): Action {
    return {
      type: holidayActionType.UPDATE,
      payload: holidayData,
    };
  }

  public static updateSuccess(holidayResponse): Action {
    return {
      type: holidayActionType.UPDATE_SUCCESS,
      payload: holidayResponse,
    };
  }

  public static updateFailed(err): Action {
    return {
      type: holidayActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  public static fetch(id: any) {
    return {
      type: holidayActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: holidayActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: holidayActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static remove(id: string) {
    return {
      type: holidayActionType.REMOVE,
      payload: id,
    };
  }

  public static removeSuccess(id: string) {
    return {
      type: holidayActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  public static removeFailed(id: string, err) {
    return {
      type: holidayActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static fetchAvailableHolidays(importData) {
    return {
      type: holidayActionType.FETCH_FOR_IMPORT,
      payload: importData,
    };
  }

  public static fetchAvailableHolidaysSuccess(holidays) {
    return {
      type: holidayActionType.FETCH_FOR_IMPORT_SUCCESS,
      payload: {
        holidays,
      },
    };
  }

  public static fetchAvailableHolidaysFailed(err) {
    return {
      type: holidayActionType.FETCH_FOR_IMPORT_FAILED,
      payload: {
        err,
      },
    };
  }

  public static batchImportHolidays(holidays: HolidayModel[]) {
    return {
      type: holidayActionType.IMPORT_SELECTED,
      payload: {
        holidays,
      },
    };
  }

  public static batchImportHolidaysSuccess(holidays: HolidayModel[], year: string, holidayGroupId: string) {
    return {
      type: holidayActionType.IMPORT_SELECTED_SUCCESS,
      payload: {
        year,
        holidays,
        holidayGroupId,
      },
    };
  }

  public static batchImportHolidaysFailed(err) {
    return {
      type: holidayActionType.IMPORT_SELECTED_FAILED,
      payload: {
        err,
      },
    };
  }

  public static clearHolidays(year: string) {
    return {
      type: holidayActionType.CLEAR,
      payload: { year },
    };
  }

  public static clearHolidaysSuccess(holidays: HolidayModel[]) {
    return {
      type: holidayActionType.CLEAR_SUCCESS,
      payload: {
        holidays,
      },
    };
  }

  public static clearHolidaysFailed(err) {
    return {
      type: holidayActionType.CLEAR_FAILED,
      payload: {
        err,
      },
    };
  }
}
