import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { HolidayAction } from './holiday.action';
import { HolidayApi } from './holiday.api';
import { HolidayImportModel, HolidayModel } from './holiday.model';

@Injectable()
export class HolidayService {
  public constructor(
    private store: Store<AppState>,
    private api: HolidayApi,
  ) {}

  public load() {
    this.store.dispatch(HolidayAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(holidayData): Observable<any> {
    this.store.dispatch(HolidayAction.add(holidayData));

    return this.api.add(holidayData).pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, holidayData) {
    this.store.dispatch(HolidayAction.update(holidayData));

    return this.api.update(id, holidayData).pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    this.store.dispatch(HolidayAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(id) {
    this.store.dispatch(HolidayAction.remove(id));

    return this.api.remove(id).pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.removeSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(holidayData) {
    if (holidayData.id) {
      return this.update(holidayData.id, holidayData);
    }

    holidayData = u.omit('id', holidayData);

    return this.add(holidayData);
  }

  public calendars(): Observable<HolidayImportModel[]> {
    return this.api.calendars();
  }

  public fetchAvailableHolidays(importData): Observable<HolidayModel[]> {
    this.store.dispatch(HolidayAction.fetchAvailableHolidays(importData));

    return this.api.fetchAvailableHolidaysForImport(importData).pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.fetchAvailableHolidaysSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.fetchAvailableHolidaysFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public batchImportHolidays(holidays: HolidayModel[], year: string, holidayGroupId?: string) {
    this.store.dispatch(HolidayAction.batchImportHolidays(holidays));

    return this.api.batchImportHolidays(holidays).pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.batchImportHolidaysSuccess(response, year, holidayGroupId));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.batchImportHolidaysFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public clearHolidays(year: string, holidayGroupId?: string) {
    this.store.dispatch(HolidayAction.clearHolidays(year));

    return this.api.clearHolidaysForSelectedYear(year, true, holidayGroupId).pipe(
      map((response) => {
        this.store.dispatch(HolidayAction.clearHolidaysSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(HolidayAction.clearHolidaysFailed(response));
        return observableThrowError(response);
      }),
    );
  }
}
